import React, { lazy, useState } from "react";
import { useNavigate } from "react-router";
import { useToken } from "../../hooks/useToken";
import { validateLoginInputs } from "../utils/validateLoginInput";
// import PasswordInput from "./password-input";
// import PhoneInputs from "./phone-input";
const PhoneInputs = lazy(()=>import("./phone-input"));
const PasswordInput = lazy(()=>import("./password-input"));

function LoginFrom() {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("+62812555444");
  const [password, setPassword] = useState("password");
  const [errors, setErrors] = useState(null);
  const { setToken } = useToken()

  const handleSubmit = (event) => {
    event.preventDefault();
    const inputValidation = validateLoginInputs({ phone, password });
    if (inputValidation) {
      setErrors(inputValidation);
    } else {
      if (errors) setErrors(null);
      const dummyToken = phone + ":" + password
      setToken(dummyToken);
      navigate("/");
    }
  };

  return (
    <form onSubmit={handleSubmit} aria-label="Login form of Neo bank account">
      <div className="w-full mt-8">
        <PhoneInputs
          value={phone}
          onChange={(phone) => setPhone(phone)}
          placeholder="Enter Phone No. +65 812-xxx"
        />
        {(errors && errors?.phone) && <p className="my-2 text-xs text-red-500">{errors?.phone}</p>}

      </div>
      <div className="w-full mt-8">
        <PasswordInput
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Please enter your password"
        />
        {(errors && errors?.password) && <p className="my-2 text-xs text-red-500">{errors?.password}</p>}
      </div>
      <div className="w-1/2 m-auto md:w-full mt-8">
        <button
          type="submit"
          className="login-button w-full !h-10 md:!h-12 !text-[#f9f9f9] !text-base  md:!text-lg rounded-full"
          aria-label="Login button"
        >
          Login
        </button>
      </div>
    </form>
  );
}

export default LoginFrom;
