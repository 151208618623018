import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { REWARD_FILTER_KEY } from "../component/utils/constants";

export const defaultRewardState = {
  category: "in-store",
  name: "",
  loading: false,
  mapFullScreen: false,
};

// Original atom.
export const rewardFilterAtom = atomWithStorage(
  REWARD_FILTER_KEY,
  defaultRewardState
);
export const clearRewardFilterAtom = atom(null, (_get, set, _data) => {
  return set(rewardFilterAtom, defaultRewardState);
});

export const categoryAtom = atom(
  (get) => get(rewardFilterAtom).category,
  (get, set, data) => {
    const prev = get(rewardFilterAtom);
    return set(rewardFilterAtom, { ...prev, category: data });
  }
);
export const nameAtom = atom(
  (get) => get(rewardFilterAtom).name,
  (get, set, data) => {
    const prev = get(rewardFilterAtom);
    return set(rewardFilterAtom, { ...prev, name: data });
  }
);

export const loaderAtom = atom(
  (get) => get(rewardFilterAtom).loading,
  (get, set, data) => {
    const prev = get(rewardFilterAtom);
    return set(rewardFilterAtom, { ...prev, loading: data });
  }
);
export const mapFullscreenAtom = atom(
  (get) => get(rewardFilterAtom).mapFullScreen,
  (get, set, data) => {
    const prev = get(rewardFilterAtom);
    return set(rewardFilterAtom, { ...prev, mapFullScreen: data });
  }
);
