export function validateLoginInputs(options){
    if(!options.phone){
        return {
            phone: "Phone is required"
        }
    } else {
        if(options.phone !== "+62812555444"){
            return {
                phone: "Incorrect phone number"
            }
        }
    }

    if(!options.password){
        return {
            password: "Password is required"
        }
    } else if (options.password.length < 8){
        return {
            password: "Password must be at least 8 characters"
        }
    } else if (options.password !== 'password') {
        return {
            password: "Incorrect password"
        }
    }
    
    return null
}