import React from "react";

function Spinner({insideSection=false}) {
  
  if(insideSection) {
    return (
      <section aria-label="Neo section loader" className="w-full py-10 grid place-items-center">
      <div className="spinner relative z-[100] spinner-bg w-36 h-36 grid place-items-center rounded-[12px]">
        <span className="ouro ouro3">
          <span className="left">
            <span className="anim"></span>
          </span>
          <span className="right">
            <span className="anim"></span>
          </span>
        </span>
      </div>
      </section>
    );
  }

  return (
    <div className="spinner sp-fixed-pos fixed z-[100] spinner-bg w-36 h-36 grid place-items-center rounded-[12px]">
      <span className="ouro ouro3">
        <span className="left">
          <span className="anim"></span>
        </span>
        <span className="right">
          <span className="anim"></span>
        </span>
      </span>
    </div>
  );
}

export default Spinner;
