import { useAtom } from "jotai";
import React, { useEffect, useState, lazy, Suspense  } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AllPages from "./component/pages";
import Spinner from "./component/ui/loader/spinner";
import { ModalProvider } from "./component/ui/modal/modal.context";
import { useServiceWorker } from "./hooks/useServiceworker";
import { loaderAtom } from "./store/reward";

const ManagedModal = lazy(() => import("./component/ui/modal/manage-modal"));
const Alert = lazy(() => import("./component/ui/alert"));


function App() {
  const [showAlert, setShowAlert] = useState(false);
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [loading] = useAtom(loaderAtom);

  // decides when to show the toast
  useEffect(() => {
    if (showReload && waitingWorker) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [waitingWorker, showReload]);

  return (
    <>
      {showAlert ? (
        <Alert onClose={() => setShowAlert(false)} onReload={reloadPage} />
      ) : null}
      {loading && <Spinner />}
      <ModalProvider>
        <Router>
          <Suspense fallback={<Spinner />}>
            <AllPages />
            <ManagedModal />
          </Suspense>
        </Router>
      </ModalProvider>
    </>
  );
}

export default App;
