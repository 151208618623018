import { lazy } from "react";
import { Route, Routes } from "react-router";
import { ROUTES } from "../utils/routes";
const MinimalLayout = lazy(() => import("../layout"));
const HomePage = lazy(() => import("../views/home"));
const RewardsListView = lazy(() => import("../views/rewards"));
const ActivityPage = lazy(() => import("../views/activity"));

const PrivateRoute = () => {
  return (
    <MinimalLayout>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path={ROUTES.REWARDS} exact element={<RewardsListView />} />
          <Route path={ROUTES.ACTIVITY} exact element={<ActivityPage />} />
        </Routes>
    </MinimalLayout>
  );
};

export default PrivateRoute;
